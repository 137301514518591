<template>
  <div
    style="
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px #eee solid;
      padding-bottom: 20px;
    "
  >
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>我的收藏</el-breadcrumb-item>
      <el-breadcrumb-item
        ><router-link to="/index/collect"
          >曲谱列表</router-link
        ></el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>

  <el-row class="pb20">
    <el-col :span="16"></el-col>
    <el-col :span="8" class="tr">
      <el-input
        v-model="tableData.condition.keyword"
        placeholder='请输入名称'
        class="width200"
      />
      <el-button
        type="primary"
        style="position: relative; top: -2px; margin-left: 2px"
        @click="search"
        ><el-icon class="pr5"><Search /></el-icon> 搜索</el-button
      >
    </el-col>
    <el-col class="tr" :span="8" :push="11" style="margin-top: -33px;">
    <span style="font-size: 15px;color: #000000;margin-right: 10px;">所属分类</span>
        <el-select v-model="value1" placeholder="全部" style="width:130px;margin-right: -45px;" @change="inputchange(value1)">
        <el-option label="全部" value="全部"></el-option>
        <el-option v-for="item in this.options" :key="item.cc_uid" :label="item.cc_name" :value="item.cc_name"></el-option>
    </el-select>
    </el-col>
  </el-row>
  <el-table
    ref="multipleTableRef"
    :data="tableData.list"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    align="center"
    border
    empty-text="暂无数据"
    @row-click="rowclick"
  >
    <el-table-column
      type="index"
      label="序号"
      width="100"
      align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_thumb"
      label="缩略图"
      width="100"
      align="left"
      header-align="center"
      show-overflow-tooltip="true"
    >
      <template #default="scope">
        <el-image
          style="width: 70px; height: 70px"
          :formatter="thumb"
          :src="scope.row.m_thumb"
          :onerror="defaultimg"
          :fit="fill"
        ></el-image>
      </template>
    </el-table-column>
    <el-table-column
      property="m_title"
      label="标题"
      min-width="200"
      align="left"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="cc_name"
      label="所属分类"
      width="200"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="c_addtime"
      sortable
      label="收藏时间"
      width="180"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      label="操作"
      width="150"
      align="center"
      show-overflow-tooltip="true"
    >
      <template #default="scope">
        <el-popconfirm
          title="要取消收藏吗？"
          @confirm="del(scope.$index, scope.row)"
        >
          <template #reference>
            <el-button size="small" type="danger"
              ><el-icon class="pr2"><Delete /></el-icon> 取消</el-button
            >
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <div class="mt20">
    <el-pagination
      v-model:currentPage="tableData.condition.page"
      v-model:page-size="tableData.condition.limit"
      :page-sizes="[20, 30, 40, 50, 100]"
      :small="small"
      :disabled="disabled"
      :background="true"
      layout="prev, pager, next, total"
      :total="tableData.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
   <el-dialog
    v-model="dialogTableVisible1"
    center
    width="80%"
    :close-on-click-modal="true"
    draggable="true"
    top="7vh"
  >
   <iframe :src="mess" style="width: 1510px;height: 900px;margin-top: -50px;margin-left: -20px;">
      </iframe>
  </el-dialog>
</template>


<script>
import { dividerProps, ElMessage } from "element-plus";
// import { query } from "express";
// import path from "path";
// import { log } from 'console';
export default {
  name: "Collect",
  data() {
    return {
      defaultimg: 'this.src="' + require("../../assets/thumb.png") + '"',
      editWindow: false,
      keyword: "",
      title: "添加曲谱",
      Search: "Search",
      tableData: {
        list: [],
        total: 0,
        condition: {
          pid:'',
          status: "",
          keyword: "",
          page: 1,
          limit: 10,
        },
      },
      form: {
        id: "",
      },
      total: 0,
      mess:'',
      bbc:false,
      value1:'全部',
      options:[],
      dialogTableVisible1:false,
    };
  },
  mounted() {
    if(this.$route.params.pid1){
        this.tableData.condition.pid=this.$route.params.pid1
        this.getListData()
        this.getListData1()
        this.value1=this.$route.params.pname1
    }else{
        this.getListData();
        this.getListData1()
    }
  },
  created() {
    //回车键绑定搜索
    document.onkeydown = (e) => {
      e = window.event || e;
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.search();
      }
    };
  },
  methods: {
    //表格缩略图格式化
    thumb(row, column) {
      if (!row.m_thumb) {
        return this.$baseUrl + "/static/images/thumb.png";
      }
    },
    //分页数量
    handleSizeChange(e) {
      console.log(e);
    },
    //分页切换
    handleCurrentChange(e) {
      this.tableData.condition.page = e;
      this.getListData();
    },
    //列表数据
    getListData() {
      //获取列表数据
      this.$request({
        url: this.$baseUrl + "/musicxml/collect/list.html",
        method: "POST",
        data: this.tableData.condition,
      }).then((res) => {
        if (res.code == 1000) {
          this.tableData.list = res.data.data;
          this.tableData.total = res.data.total;
          console.log(res.data);
          console.log(res);
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    //删除
    del(index, data) {
      this.$request({
        url: this.$baseUrl + "/musicxml/collect/edit.html",
        method: "delete",
        data: { id: data.c_id },
      }).then((res) => {
        if (res.code == 1000) {
          this.getListData();
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    //搜索
    search() {
      this.getListData();
    },
    rowclick(row, column, event) {
      // this.getListData()
      console.log(row);
      let mess =
        "https://music.thedream.tech/musicxmlengine2/index.html?url=" +
        row.m_path+'&title='+row.m_title;
        console.log(mess);
        this.mess=mess;
        // this.bbc=true;
         this.dialogTableVisible1=true
    //   this.$router.push({path:'/mymusicxmlout',query:{path1:mess}})
    },
    close(){
      this.bbc=false;
    },
    inputchange(id){
        console.log(id);
       if(id!=='全部'){
          this.tableData.condition.keyword=id
          this.getListData()
          this.tableData.condition.keyword=''
        }else if(id=='全部'){
          this.tableData.condition.keyword=''
          this.getListData()
        }
    },
    getListData1(){
                //获取列表数据
                this.$request({
                    url: this.$baseUrl + '/musicxml/collectcate/list.html',
                    method: "POST",
                    data:this.tableData.condition
                }).then(res=>{
                    if (res.code == 1000) {
                        this.options= res.data.data
                        console.log(this.options);
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
            },
  }
};
</script>
<style lang="less">
.time .el-input {
  width: 100% !important;
}
.el-dialog__headerbtn{
  width: 40px;
  height: 40px;
  background-color: red;
  position: absolute;
  top: 4px;
  right: 22px;
}
.el-dialog__headerbtn .el-dialog__close{
  margin-bottom: -20px;
}
</style>