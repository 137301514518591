<template>
  <div
    style="
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px #eee solid;
      padding-bottom: 20px;
    "
  >
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>我的曲谱</el-breadcrumb-item>
      <el-breadcrumb-item
        ><router-link to="/index/mymusicxml"
          >曲谱列表</router-link
        ></el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
  <el-row class="pb20">
    <el-col :span="16">
      <el-button type="primary" @click="add()"
        ><el-icon class="pr5"><Plus /></el-icon> 添加曲谱</el-button
      >
      <el-button type="success" @click="collect()"
        ><el-icon class="pr5"><Plus /></el-icon> 添加至收藏</el-button
      >
      <el-button type="warning" @click="batch()" v-if="manager == 1"
        ><el-icon><Upload /></el-icon> 批量上传</el-button
      >
    </el-col>
     <el-col :span="8" class="tr">
      <el-input
        v-model="tableData.condition.keyword"
        placeholder='请输入名称'
        class="width200"
      />
      <el-button
        type="primary"
        style="position: relative; top: -2px; margin-left: 2px"
        @click="search"
        ><el-icon class="pr5"><Search /></el-icon> 搜索</el-button
      >
    </el-col>
    <el-col class="tr" :span="8" :push="11" style="margin-top: -33px;">
    <span style="font-size: 15px;color: #000000;margin-right: 10px;">所属分类</span>
        <el-select v-model="value1" placeholder="全部" style="width:130px;margin-right: -45px;" @change="inputchange(value1)">
        <el-option label="全部" value="全部"></el-option>
        <el-option v-for="item in this.cate" :key="item.mc_id" :label="item.mc_name" :value="item.mc_name"></el-option>
    </el-select>
    </el-col>
  </el-row>
  <el-table
    ref="multipleTableRef"
    :data="tableData.list"
    style="width: 100%"
    @selection-change="selection"
    align="center"
    border
    empty-text="暂无数据"
    @row-click="rowclick"
  >
    <el-table-column type="selection" width="40" />
    <el-table-column
      type="index"
      label="序号"
      width="100"
      align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_thumb"
      label="缩略图"
      width="100"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    >
      <template #default="scope">
        <el-image
          style="width: 50px; height: 50px"
          :formatter="thumb"
          :src="scope.row.m_thumb"
          :onerror="defaultimg"
          :fit="fill"
        ></el-image>
      </template>
    </el-table-column>
    <el-table-column
      property="m_title"
      label="标题"
      min-width="200"
      align="left"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="mc_name"
      label="所属分类"
      width="200"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_addtime"
      sortable
      label="上传时间"
      width="180"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_updatetime"
      sortable
      label="更新时间"
      width="180"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_view"
      sortable
      label="查看"
      width="100"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_collect"
      sortable
      label="收藏"
      width="100"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_fabulous"
      sortable
      label="点赞"
      width="100"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_price"
      sortable
      label="下载价格"
      width="120"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      label="操作"
      width="170"
      align="center"
      show-overflow-tooltip="true"
    >
      <template #default="scope">
        <el-button size="small" @click="modify(scope.$index, scope.row)"
          ><el-icon class="pr2"><Edit /></el-icon> 编辑</el-button
        >
        <el-popconfirm
          title="确认要删除该曲谱吗？"
          @confirm="del(scope.$index, scope.row)"
        >
          <template #reference>
            <el-button size="small" type="danger"
              ><el-icon class="pr2"><Delete /></el-icon> 删除</el-button
            >
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <div class="mt20">
    <el-pagination
      v-model:currentPage="tableData.condition.page"
      v-model:page-size="tableData.condition.limit"
      :page-sizes="[20, 30, 40, 50, 100]"
      :small="small"
      :disabled="disabled"
      :background="true"
      layout="prev, pager, next, total,sizes"
      :total="tableData.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>

  <!-- 添加编辑窗口 -->
  <el-dialog
    v-model="editWindow"
    :title="title"
    width="700px"
    :close-on-click-modal="false"
    draggable="true"
  >
    <el-form
      :model="form"
      label-width="120px"
      :rules="baseRules"
      ref="baseForm"
    >
      <el-form-item label="XML曲谱" prop="xml">
        <el-upload
          class="upload-xml"
          action="#"
          :http-request="uploadFileAction"
          :limit="1"
          :on-exceed="handleExceed"
          :before-upload="beforeFileUpload"
          method="POST"
          v-model="form.xml"
          :file-list="fileList"
          :on-change="fileChange"
          :on-remove="removeXml"
          accept=".xml,.musicxml"
        >
          <template #trigger>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="请上传XML格式文件，大小不超过2M"
              placement="right-start"
            >
              <el-button type="primary">选择曲谱</el-button>
            </el-tooltip>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item label="名称" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入标题"
          :value="form.title"
        />
      </el-form-item>
      <el-form-item label="分类" prop="pid">
        <el-select v-model="form.pid" placeholder="请选择分类">
          <el-option
            v-for="item in cate"
            :label="item.mc_name"
            :value="item.mc_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="缩略图">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="请上传jpg|jpeg|png格式文件，大小不超过500kb"
          placement="right-start"
        >
          <!--  -->
          <el-upload
            class="avatar-uploader"
            action="#"
            :http-request="uploadImgAction"
            :show-file-list="false"
            :before-upload="beforeImageUpload"
          >
            <img v-if="form.thumb" :src="form.thumb" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="价格">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="此价格为预留，后期用户下载PDF采用收费模式，上传者与平台共同分成"
          placement="right-start"
        >
          <el-input
            v-model="form.price"
            placeholder="请输入价格，默认￥0.00"
            style="width: 200px"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item label="描述">
        <el-input
          v-model="form.desc"
          type="textarea"
          placeholder="请输入描述"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitBtn">保存</el-button>
        <el-button @click="cancleBtn">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <!-- 批量上传 -->
  <el-dialog
    v-model="batchWindow"
    :title="title"
    width="700px"
    :close-on-click-modal="false"
    draggable="true"
  >
    <el-form
      :model="formBatch"
      label-width="120px"
      :rules="baseRules"
      ref="baseFormBatch"
    >
      <el-form-item label="分类" prop="pid">
        <el-select v-model="formBatch.pid" placeholder="请选择分类">
          <el-option label="请选择" value="" />
          <el-option
            v-for="item in cate"
            :label="item.mc_name"
            :value="item.mc_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="XML曲谱">
        <el-upload
          class="upload-xml"
          :action="action"
          :on-change="onChangeBatch"
          :file-list="fileListBatch"
          :on-remove="removeXmlBatch"
          :before-upload="beforeFileUploadBatch"
          multiple="true"
          limit="100"
          accept=".xml,.musicxml"
        >
          <template #trigger>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="请上传XML格式文件，大小不超过2M，每次不能超过100个文件"
              placement="right-start"
            >
              <el-button type="primary">选择曲谱</el-button>
            </el-tooltip>
          </template>
        </el-upload>
      </el-form-item>
      <!-- <el-form-item label="添加至收藏">
                <el-select v-model="formBatch.cid" placeholder="请选择分类">
                    <el-option label="请选择" value="" />
                    <el-option v-for="item in collectcate" :label="item.cc_name" :value="item.cc_id" />
                </el-select>
            </el-form-item> -->
      <el-form-item label="价格">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="此价格为预留，后期用户下载PDF采用收费模式，上传者与平台共同分成"
          placement="right-start"
        >
          <el-input
            v-model="form.price"
            placeholder="请输入价格，默认￥0.00"
            style="width: 200px"
          />
        </el-tooltip>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitBtnBatch">保存</el-button>
        <el-button @click="cancleBtnBatch">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <!-- 收藏分类窗口 -->
  <el-dialog
    v-model="collectWindow"
    :title="title"
    width="500px"
    :close-on-click-modal="false"
    draggable="true"
  >
    <el-form
      :model="collectform"
      label-width="120px"
      :rules="collectRules"
      ref="collectForm"
    >
      <el-form-item label="分类" prop="id">
        <el-select v-model="collectform.id" placeholder="请选择分类">
          <el-option label="请选择" value="" />
          <el-option
            v-for="item in collectcate"
            :label="item.cc_name"
            :value="item.cc_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="collectSubmit">保存</el-button>
        <el-button @click="collectCancleBtn">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <!-- <div v-if="bbc" style="width: 1900px;height: 900px;z-index: 5;background-color: blue;">
      <iframe :src="mess" style="min-width: 1690px;height: 900px; z-index: 3;border-color: aqua;">
      </iframe>
      <button style="position:fixed;top:0;right: 1.5%;z-index: 3;width: 30px;height: 30px;" @click="close">×</button></div> -->
       <el-dialog
    v-model="dialogTableVisible1"
    center
    width="80%"
    :close-on-click-modal="true"
    draggable="true"
    top="7vh"
  >
   <iframe :src="mess" style="width: 1510px;height: 900px;margin-top: -50px;margin-left: -20px;">
      </iframe>
  </el-dialog>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  name: "MyMusicXml",
  components: {},
  data() {
    return {
      action: this.$baseUrl + "/musicxml/upload/xml.html",
      //默认图片
      defaultimg: 'this.src="' + require("../../assets/thumb.png") + '"',
      //编辑窗口
      editWindow: false,
      //收藏窗口
      collectWindow: false,
      //批量上传
      batchWindow: false,
      //编辑窗口标题
      title: "添加曲谱",
      //选中的数据
      selectData: [],
      //收藏分类
      collectcate: [],
      //列表总数据
      total: 0,
      //列表灵气
      tableData: {
        list: [],
        total: 0,
        condition: {
          status: "",
          keyword: "",
          pid:"",
          page: 1,
          limit: 10,
        },
      },
      //分类
      cate: [],
      //添加或编辑数据
      form: {
        id: "",
        title: "12345",
        pid: "",
        xml: "",
        thumb: "",
        price: "",
        desc: "",
      },
      collectform: {
        id: "",
      },
      formBatch: {
        pid: "",
        price: "",
        xml: [],
        cid: "",
      },
      fileList: [],
      fileListBatch: [],
      //表单验证
      baseRules: {
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
        pid: [{ required: true, message: "请选择分类", trigger: "blur" }],
        xml: [{ required: true, message: "请上传曲谱", trigger: "blur" }],
      },
      collectRules: {
        id: [{ required: true, message: "请选择分类", trigger: "blur" }],
      },
      manager: "",
      wenname: "",
      bbc:false,
      value1:'全部',
      options:[],
      uid:'',
      dialogTableVisible1:false,
      title1:'',
    }
  },
  mounted() {
    if(this.$route.params.pid1){
        this.tableData.condition.pid=this.$route.params.pid1
        this.getListData()
        this.value1=this.$route.params.pname1
    }else{
        this.getListData();
    }
    let token = sessionStorage.getItem("token");
    this.manager = JSON.parse(token).u_manager;
  },
  created() {
    //回车键绑定搜索
    document.onkeydown = (e) => {
      e = window.event || e;
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.search();
      }
    };
  },
  methods: {
    //收藏提交
    collectSubmit() {
      this.$refs.collectForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.$request({
          url: this.$baseUrl + "/musicxml/collect/musicxml.html",
          method: "post",
          data: {
            data: JSON.stringify(this.selectData),
            id: this.collectform.id,
          },
        }).then((res) => {
          if (res.code == 1000) {
            this.collectWindow = false;
            this.collectform.id = "";
          } else {
            ElMessage.error(res.msg);
          }
        });
      });
    },
    //添加至收藏窗口
    collect() {
      if (this.selectData.length == 0) {
        ElMessage.error("请选择曲谱");
        return;
      }
      this.$request({
        url: this.$baseUrl + "/musicxml/collectcate/listApi.html",
        method: "post",
      }).then((res) => {
        this.collectcate = res.data;
        //弹出收藏分类选择窗
        this.collectWindow = true;
      });
    },
    //收藏取消窗口
    collectCancleBtn() {
      this.collectWindow = false;
    },
    //选中的数据
    selection(data) {
      this.selectData = data;
    },
    onChangeBatch() {
      this.formBatch.xml = JSON.stringify(this.fileListBatch);
    },
    //取消
    cancleBtnBatch() {
      this.batchWindow = false;
      this.formBatch.pid = "";
      this.formBatch.xml = [];
      this.formBatch.price = "";
    },
    //批量上传
    removeXmlBatch(e) {
      this.formBatch.xml = JSON.stringify(this.fileListBatch);
    },
    uploadFileActionBatch(option) {
      let param = new FormData();
      param.append("file", option.file);
      this.$request({
        url: this.$baseUrl + "/musicxml/upload/xml.html",
        method: "POST",
        data: param,
      }).then((res) => {
        if (res.code == 1000) {
          let xml = this.formBatch.xml;
          xml.push(res.data);
          this.xml = xml;
          console.log(this.xml);
          console.log(this.fileListBatch);
          ElMessage.success("文件上传成功！");
        } else {
          ElMessage.error("文件上传失败！");
        }
      });
    },
    //文件上传前检查
    beforeFileUploadBatch(rawFile) {
      console.log(rawFile);
      if (rawFile.type !== "text/xml") {
        ElMessage.error("请上传XML或者musicxml格式文件");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("文件大小不能超过2M");
        return false;
      }
    },
    batch() {
      // this.$request({
      //     url: this.$baseUrl + '/musicxml/collectcate/listApi.html',
      //     method:'post',
      // }).then(res=>{
      //     this.collectcate = res.data
      // })
      this.batchWindow = true;
    },
    removeXml(e) {
      console.log(e.name);
      console.log(123);
      this.form.title = "";
    },
    uploadImgAction(option) {
      let param = new FormData();
      param.append("file", option.file);
      this.$request({
        url: this.$baseUrl + "/musicxml/upload/image.html",
        method: "POST",
        data: param,
      }).then((res) => {
        if (res.code == 1000) {
          this.form.thumb = res.data;
          ElMessage.success("图片上传成功！");
        } else {
          ElMessage.error("图片上传失败！");
        }
      });
    },
    uploadFileAction(option) {
      let param = new FormData();
      param.append("file", option.file);
      this.$request({
        url: this.$baseUrl + "/musicxml/upload/xml.html",
        method: "POST",
        data: param,
      }).then((res) => {
        if (res.code == 1000) {
          this.form.xml = res.data.path;
          ElMessage.success("文件上传成功！");
        } else {
          ElMessage.error("文件上传失败！");
        }
      });
    },
    //文件上传前检查
    beforeFileUpload(rawFile) {
      console.log(rawFile);
      var cvb = rawFile.name.substring(rawFile.name.lastIndexOf(".") + 1);
      console.log(rawFile);
      console.log(cvb);
      const exten = cvb === "xml";
      const exten1 = cvb === "musicxml";
      if (!exten && !exten1) {
        this.$message({
          message: "上传文件只能是xml或者musicxml",
          type: "warning",
        });
      }
      if (rawFile.type !== "text/xml") {
        ElMessage.error("请上传XML或者musicxml格式文件");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("文件大小不能超过2M");
        this.form.title = "";
        return false;
      }
      return true;
    },
    beforeImageUpload(rawFile) {
      if (
        rawFile.type !== "image/jpeg" &&
        rawFile.type !== "image/png" &&
        rawFile.type !== "image/jpg"
      ) {
        ElMessage.error("文件格式错误");
        return false;
      } else if (rawFile.size / 1024 > 50) {
        ElMessage.error("文件大小不能超过500kb");
        return false;
      }
      return true;
    },
    //表格缩略图格式化
    thumb(row, column) {
      if (!row.m_thumb) {
        return this.$baseUrl + "/static/images/thumb.png";
      }
    },
    //分页数量
    handleSizeChange(e) {
      this.form.limit = e;
      this.getListData();
    },
    //分页切换
    handleCurrentChange(e) {
      this.tableData.condition.page = e;
      this.getListData();
    },
    //列表数据
    getListData() {
      //获取列表数据
      this.$request({
        url: this.$baseUrl + "/musicxml/mymusicxml/list.html",
        method: "POST",
        data: this.tableData.condition,
      }).then((res) => {
        console.log(res);
        if (res.code == 1000) {
          this.tableData.list = res.data.data;
          this.tableData.total = res.data.total;
          this.cate = res.other;
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    //添加
    add() {
      this.title = "添加曲谱";
      this.form.id = "";
      this.form.title = "";
      this.form.pid = "";
      this.form.xml = "";
      this.form.thumb = "";
      this.form.price = "";
      this.form.desc = "";
      this.editWindow = true;
    },
    //修改
    modify(index, data) {
      this.title = "编辑曲谱";
      this.form.id = data.m_id;
      this.form.title = data.m_title;
      this.form.pid = data.m_pid;
      this.form.xml = data.m_path;
      this.form.thumb = data.m_thumb;
      this.form.price = data.m_price;
      this.form.desc = data.m_desc;
      this.fileList = [
        {
          name: data.m_title,
          url: data.m_path,
        },
      ];
      this.editWindow = true;
      //移除xml表单验证
      if (this.form.xml) {
        this.$refs["baseForm"].clearValidate(["xml"]);
      }
    },
    //移除表单验证
    fileChange(e) {
      if (this.form.xml) {
        this.$refs.baseForm.clearValidate(["xml"]);
      } else {
        this.$refs.baseForm.resetFields(["xml"]);
      }
      this.form.title = e.name;
      this.form.title = this.form.title.substring(
        0,
        this.form.title.lastIndexOf(".")
      );
    },
    //删除
    del(index, data) {
      this.form.id = data.m_id;
      this.form.title = data.m_title;
      this.form.pid = data.m_pid;
      this.form.xml = data.m_path;
      this.form.thumb = data.m_thumb;
      this.form.price = data.m_price;
      this.form.desc = data.m_desc;
      this.$request({
        url: this.$baseUrl + "/musicxml/mymusicxml/edit.html",
        method: "delete",
        data: this.form,
      }).then((res) => {
        if (res.code == 1000) {
          this.getListData();
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    //取消
    cancleBtn() {
      this.editWindow = false;
      this.form.id = "";
      this.form.title = "";
      this.form.pid = "";
      this.form.xml = "";
      this.form.thumb = "";
      this.form.price = "";
      this.form.desc = "";
    },
    //搜索
    search() {
            this.getListData();
    },
    //提交
    submitBtn() {
      this.$refs.baseForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.$request({
          url: this.$baseUrl + "/musicxml/mymusicxml/edit.html",
          method: "post",
          data: this.form,
        }).then((res) => {
          if (res.code == 1000) {
            this.editWindow = false;
            this.form.id = "";
            this.form.title = "";
            this.form.pid = "";
            this.form.xml = "";
            this.form.thumb = "";
            this.form.price = "";
            this.form.desc = "";
            this.getListData();
          } else {
            ElMessage.error(res.msg);
          }
        });
      });
    },
    //提交
    submitBtnBatch() {
      this.$refs.baseFormBatch.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.$request({
          url: this.$baseUrl + "/musicxml/mymusicxml/batch.html",
          method: "post",
          data: this.formBatch,
        }).then((res) => {
          if (res.code == 1000) {
            this.batchWindow = false;
            this.formBatch.pid = "";
            this.formBatch.xml = "";
            this.formBatch.price = "";
            this.getListData();
          } else {
            ElMessage.error(res.msg);
          }
        });
      });
    },
    rowclick(row) {
      console.log(row);
      let mess =
        "https://music.thedream.tech/musicxmlengine2/index.html?url=" +
        row.m_path +
        "&title=" +
        row.m_title;
      console.log(mess);
      this.mess = mess;
      // this.bbc=true;
      this.title1=row.m_title
      this.dialogTableVisible1=true
      console.log(this.dialogTableVisible1);
    },
     close(){
      this.bbc=false;
    },
    inputchange(id){
        console.log(id);
        if(id!=='全部'){
          this.tableData.condition.keyword=id
          this.getListData()
          this.tableData.condition.keyword=''
        }else if(id=='全部'){
          this.tableData.condition.keyword=''
          this.getListData()
        }
        // if(this.value1=="请输入分类"){
        //     this.tableData.condition.pid=this.tableData.condition.keyword
        //     this.tableData.condition.keyword=''
        //     console.log(this.tableData.condition.pid);
        // }
    },
   
    //上传文件成功
    // addsuccess(){
    //     // if(this.wenname.length!=0){
    //     //     this.$refs['baseForm'].clearValidate(['title']);
    //     // }
    //     this.$nextTick(()=>{
    //         this.$refs.baseForm.clearValidate('title');
    //     })
    // }
  },
};
</script>
<style lang="less">
.avatar-uploader .el-upload {
  /*border: 1px dashed #d9d9d9;*/
  border: 1px dashed #437fff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  /*  overflow: hidden;*/
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.upload-xml {
  width: 100%;
}
.time .el-input {
  width: 100% !important;
}
.el-dialog__headerbtn{
  width: 40px;
  height: 40px;
  background-color: red;
  position: absolute;
  top: 4px;
  right: 22px;
}
.el-dialog__headerbtn .el-dialog__close{
  margin-bottom: -20px;
}
</style>