<template>
  <div
    style="
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px #eee solid;
      padding-bottom: 20px;
    "
  >
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>曲谱管理</el-breadcrumb-item>
      <el-breadcrumb-item
        ><router-link to="/index/musicxml"
          >曲谱列表</router-link
        ></el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
  <el-row class="pb20">
    <el-col :span="16"></el-col>
    <el-col :span="8" class="tr">
      <el-input
        v-model="tableData.condition.keyword"
        placeholder="请输入名称"
        class="width200"
      />
      <el-button
        type="primary"
        style="position: relative; top: -2px; margin-left: 2px"
        @click="search"
        ><el-icon class="pr5"><Search /></el-icon> 搜索</el-button
      >
    </el-col>
  </el-row>
  <el-table
    ref="multipleTableRef"
    :data="tableData.list"
    style="width: 100%"
    @selection-change="handleSelectionChange"
    align="center"
    border
    empty-text="暂无数据"
    @row-click="rowclick"
  >
    <el-table-column
      type="index"
      label="序号"
      min-width="100"
      align="center"
      :index="hIndex"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_thumb"
      label="缩略图"
      width="80"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    >
      <template #default="scope">
        <el-image
          style="width: 50px; height: 50px"
          :formatter="thumb"
          :src="scope.row.m_thumb"
          :onerror="defaultimg"
          :fit="fill"
        ></el-image>
      </template>
    </el-table-column>
    <el-table-column
      property="m_title"
      label="名称"
      min-width="200"
      align="left"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_desc"
      label="简介"
      min-width="100"
      align="left"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="u_nickname"
      label="上传用户"
      width="200"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
      formatter
    />
    <el-table-column
      property="m_addtime"
      sortable
      label="上传时间"
      width="180"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_status"
      :formatter="status"
      label="状态"
      width="80"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    >
      <template #default="scope">
        <span style="color: #909399" v-if="scope.row.m_status == 0">隐藏</span>
        <span style="color: #67c23a" v-else-if="scope.row.m_status == 1"
          >通过</span
        >
        <span style="color: #e6a23c" v-else-if="scope.row.m_status == 2"
          >待审核</span
        >
        <span style="color: #f56c6c" v-else-if="scope.row.m_status == 3"
          >未通过</span
        >
        <span style="color: #409eff" v-else-if="scope.row.m_status == 4"
          >私密</span
        >
      </template>
    </el-table-column>
    <el-table-column
      property="m_view"
      sortable
      label="查看"
      width="100"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_collect"
      sortable
      label="收藏"
      width="100"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_fabulous"
      sortable
      label="点赞"
      width="100"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_price"
      sortable
      label="下载价格"
      width="120"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      property="m_mark"
      label="审核意见"
      width="120"
      align="center"
      header-align="center"
      show-overflow-tooltip="true"
    />
    <el-table-column
      label="操作"
      width="160"
      align="center"
      show-overflow-tooltip="true"
    >
      <template #default="scope">
        <div class="flex flex-wrap items-center" style="float: left">
          <el-dropdown>
            <el-button type="primary" size="small"
              >审核<el-icon class="el-icon--right"><arrow-down /></el-icon
            ></el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="examine(1, scope.row.m_id)"
                  >审核通过</el-dropdown-item
                >
                <el-dropdown-item @click="examine(3, scope.row.m_id)"
                  >审核不通过</el-dropdown-item
                >
                <el-dropdown-item @click="examine(2, scope.row.m_id)"
                  >待审核</el-dropdown-item
                >
                <el-dropdown-item @click="examine(4, scope.row.m_id)"
                  >设为私密</el-dropdown-item
                >
                <el-dropdown-item @click="examine(0, scope.row.m_id)"
                  >隐藏</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <el-popconfirm
          title="确认要删除该曲谱吗？"
          @confirm="del(scope.$index, scope.row)"
        >
          <template #reference>
            <el-button size="small" type="danger"
              ><el-icon class="pr2"><Delete /></el-icon> 删除</el-button
            >
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <div class="mt20">
    <el-pagination
      v-model:currentPage="tableData.condition.page"
      v-model:page-size="tableData.condition.limit"
      :page-sizes="[20, 30, 40, 50, 100]"
      :small="small"
      :disabled="disabled"
      :background="true"
      layout="prev, pager, next, total"
      :total="tableData.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>

  <!-- Form -->
  <el-dialog title="审核不通过" v-model="examineShow" width="300px">
    <el-form :model="form" :rules="baseRules" ref="baseForm">
      <el-form-item label="原因" prop="reson">
        <el-input v-model="form.reson" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="examineShow = false">取消</el-button>
        <el-button type="primary" @click="auditfailed">确认</el-button>
      </span>
    </template>
  </el-dialog>
  <div v-if="bbc" style="width: 1900px; height: 900px">
    <iframe
      :src="mess"
      style="
        min-height: 100%;
        min-width: 1690px;
        position: fixed;
        bottom: 0;
        left: 11.5%;
        z-index: 3;
        border-color: aqua;
      "
    ></iframe
    ><button
      style="
        position: fixed;
        top: 0;
        right: 1.5%;
        z-index: 3;
        width: 30px;
        height: 30px;
      "
      @click="close"
    >
      ×
    </button>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  name: "MusicXml",
  components: {},
  data() {
    return {
      //默认图片
      defaultimg: 'this.src="' + require("../../assets/thumb.png") + '"',
      //列表总数据
      total: 0,
      //列表灵气
      tableData: {
        list: [],
        total: 0,
        condition: {
          status: "",
          keyword: "",
          page: 1,
          limit: 10,
        },
      },
      form: {
        id: "",
        status: "",
        reson: "",
      },
      //分类
      cate: [],
      examineShow: false, //审核不通过窗口
      baseRules: {
        reson: [{ required: true, message: "请输入原因", trigger: "blur" }],
      },
      userinfo: "",
      bbc: false,
    };
  },
  mounted() {
    let token = sessionStorage.getItem("token");
    this.userinfo = JSON.parse(token);
    this.getListData();
  },
  created() {
    //回车键绑定搜索
    document.onkeydown = (e) => {
      e = window.event || e;
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.search();
      }
    };
  },
  methods: {
    //审核失败
    auditfailed() {
      this.$refs.baseForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.$request({
          url: this.$baseUrl + "/musicxml/musicxml/edit.html",
          method: "put",
          data: this.form,
        }).then((res) => {
          if (res.code == 1000) {
            this.examineShow = false;
            this.form.id = "";
            this.form.status = "";
            this.form.reson = "";
            this.getListData();
          } else {
            ElMessage.error(res.msg);
          }
        });
      });
    },
    //审核
    examine(status, id) {
      if (status == 3) {
        this.form.id = id;
        this.form.status = status;
        this.form.reson = "";
        this.examineShow = true;
      } else {
        this.$request({
          url: this.$baseUrl + "/musicxml/musicxml/edit.html",
          method: "put",
          data: { id: id, status: status },
        }).then((res) => {
          if (res.code == 1000) {
            this.getListData();
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    },
    //序号
    hIndex(index) {
      this.tableData.condition.page <= 1
        ? (this.tableData.condition.page = 1)
        : this.tableData.condition.page;
      if (this.tableData.condition.page != 1) {
        return (
          index +
          1 +
          (this.tableData.condition.page - 1) * this.tableData.condition.limit
        );
      }
      return index + 1;
    },
    //表格缩略图格式化
    thumb(row) {
      if (!row.m_thumb) {
        return this.$baseUrl + "/static/images/thumb.png";
      }
    },
    //分页数量
    handleSizeChange(e) {
      console.log(e);
    },
    //分页切换
    handleCurrentChange(e) {
      this.tableData.condition.page = e;
      this.getListData();
    },
    //列表数据
    getListData() {
      //获取列表数据
      this.$request({
        url: this.$baseUrl + "/musicxml/musicxml/list.html",
        method: "POST",
        data: this.tableData.condition,
      }).then((res) => {
        if (res.code == 1000) {
          this.tableData.list = res.data.data;
          this.tableData.total = res.data.total;
          this.cate = res.other;
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    //删除
    del(index, data) {
      this.$request({
        url: this.$baseUrl + "/musicxml/musicxml/edit.html",
        method: "delete",
        data: { id: data.m_id },
      }).then((res) => {
        if (res.code == 1000) {
          this.getListData();
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    //取消
    cancleBtn() {
      this.editWindow = false;
    },
    //搜索
    search() {
      this.getListData();
    },
    rowclick(row, column, event) {
      // this.getListData()
      console.log(row);
      let mess =
        "https://music.thedream.tech/musicxmlengine2/index.html?url=" +
        row.m_path +
        "&title=" +
        row.m_title;
      console.log(mess);
      this.mess = mess;
      this.bbc = true;
      //   this.$router.push({path:'/mymusicxmlout',query:{path1:mess}})
    },
    close() {
      this.bbc = false;
    },
  },
};
</script>
<style lang="less">
.avatar-uploader .el-upload {
  /*border: 1px dashed #d9d9d9;*/
  border: 1px dashed #437fff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  /*  overflow: hidden;*/
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.upload-xml {
  width: 100%;
}
.time .el-input {
  width: 100% !important;
}
</style>