<template>
    <div style="width: 100%;height: auto;overflow: hidden;margin-bottom: 20px;border-bottom: 1px #eee solid;padding-bottom: 20px;">
        <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>曲谱管理</el-breadcrumb-item>
            <el-breadcrumb-item><router-link to="/index/findmusicxml">求谱列表</router-link></el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="pb20">
        <el-col :span="16"></el-col>
        <el-col :span="8" class="tr">
            <el-input v-model="tableData.condition.keyword" placeholder="请输入名称" class="width200"/>
            <el-button type="primary" style="position:relative;top:-2px;margin-left:2px;" @click="search"><el-icon class="pr5"><Search /></el-icon> 搜索</el-button>
        </el-col>
    </el-row>
    <el-table ref="multipleTableRef" :data="tableData.list" style="width: 100%" @selection-change="handleSelectionChange" align="center" border empty-text="暂无数据">
        <el-table-column type="index" label="序号" min-width="60" align="center" :index="hIndex" show-overflow-tooltip="true"/>
        <el-table-column property="fm_title" label="名称" min-width="200" align="left" header-align="center" show-overflow-tooltip="true"/>
        <el-table-column property="u_nickname" label="发起人" width="200" align="center" header-align="center" show-overflow-tooltip="true"/>
        <el-table-column property="fm_addtime" sortable label="求谱时间" width="180" align="center" header-align="center" show-overflow-tooltip="true" formatter/>
        <el-table-column property="fm_count" sortable label="求谱人数" width="180" align="center" header-align="center" show-overflow-tooltip="true" />
        <el-table-column property="total" sortable label="上传曲谱数" width="180" align="center" header-align="center" show-overflow-tooltip="true" />
        <el-table-column label="操作" width="120" align="center" show-overflow-tooltip="true">
            <template #default="scope">
                <el-popconfirm title="确认要删除该曲谱吗？" @confirm="del(scope.$index, scope.row)">
                    <template #reference>
                         <el-button size="small" type="danger"><el-icon class="pr2"><Delete /></el-icon> 删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>
    <div class="mt20">
        <el-pagination
            v-model:currentPage="tableData.condition.page"
            v-model:page-size="tableData.condition.limit"
            :page-sizes="[20, 30, 40, 50, 100]"
            :small="small"
            :disabled="disabled"
            :background=true
            layout="prev, pager, next, total"
            :total="tableData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
    import { ElMessage } from 'element-plus'
    export default {
        name: 'FindMusicXml',
        components:{ },
        data(){
            return {
                //列表总数据
                total:0,
                //列表灵气
                tableData : {
                    list:[],
                    total:0,
                    condition:{
                        status:'',
                        keyword:'',
                        page:1,
                        limit:10,
                    },
                },
                form:{
                    id:'',
                    status:'',
                    reson:''
                },
                //分类
                cate:[],
            }
        },
        mounted() {
            let token = sessionStorage.getItem('token')
            this.userinfo = JSON.parse(token)
            this.getListData()
        },
        created() {
            //回车键绑定搜索
            document.onkeydown = (e) => {
                e = window.event || e
                if (e.keyCode == 13 || e.keyCode == 100) {
                    this.search();
                }
            }
        },
        methods:{
            //序号
            hIndex (index){
                this.tableData.condition.page <= 1 ? this.tableData.condition.page = 1 : this.tableData.condition.page
                if(this.tableData.condition.page != 1) {
                    return index + 1 + ((this.tableData.condition.page - 1) * this.tableData.condition.limit)
                }
                return index + 1
            },
            //分页数量
            handleSizeChange(e){
                console.log(e)
            },
            //分页切换
            handleCurrentChange(e){
                this.tableData.condition.page = e
                this.getListData()
            },
            //列表数据
            getListData(){
                //获取列表数据
                this.$request({
                    url: this.$baseUrl + '/musicxml/findmusicxml/list.html',
                    method: "POST",
                    data:this.tableData.condition
                }).then(res=>{
                    if (res.code == 1000) {
                        this.tableData.list = res.data.data
                        this.tableData.total = res.data.total
                        this.cate = res.other
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
            },
            //删除
            del(index,data){
                this.$request({
                    url: this.$baseUrl + '/musicxml/findmusicxml/edit.html',
                    method: "delete",
                    data:{id:data.fm_id}
                }).then(res=>{
                    if (res.code == 1000) {
                        this.getListData();
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
            },
            //取消
            cancleBtn(){
                this.editWindow = false
            },
            //搜索
            search(){
                this.getListData()
            },
        }
    }
    
</script>
<style lang="less">
    .avatar-uploader .el-upload {
        /*border: 1px dashed #d9d9d9;*/
        border: 1px dashed #437FFF;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
      /*  overflow: hidden;*/
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .avatar {
        width: 80px;
        height: 80px;
        display: block;
    }
    .upload-xml{
        width:100%;
    }
    .time .el-input{
        width:100% !important;
    }
</style>