//1.导入Vue的构造函数
import { createApp } from 'vue'
//2.导入App根组件
import App from './App.vue'
//导入组件及样式
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
//导入路由模块
import router from './router'
//引入全局样式
import './index.css'
//Vue标题设置组件
import VueWechatTitle from 'vue-wechat-title'
//引入封装的axios
import request from './lib/request.js'

//屏蔽浏览器console面板的提示消息
// Vue.config.productionTip = false
const app = createApp(App)
//全局挂载axios
app.config.globalProperties.$request = request

// app.config.globalProperties.$baseUrl = 'http://192.168.1.113:81'
app.config.globalProperties.$baseUrl = 'https://music.thedream.tech'
//挂载路由
app.use(router) 
//挂载element plus组件
app.use(ElementPlus,{
    locale: zhCn,
}) 
//挂载修改标题组件
app.use(VueWechatTitle) 

//引入 element 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

//获取当前客户端时间
// Math.round(new Date().getTime()/1000).toString();
//获取一次服务器时间
// app.config.globalProperties.$request({ url: '/login.html', method: "post",data:{usernmae:11}})



//3.创建vue实例对象
app.mount('#app')
