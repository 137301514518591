<template>
	
</template>

<script>
	export default {
		data(){
            return{
                path:this.$route.query.path1,
            }
        },
        mounted(){
            location=this.path
        }
}
</script>

<style lang="less">

</style>