<template>
    <div style="width: 100%;height: auto;overflow: hidden;margin-bottom: 20px;border-bottom: 1px #eee solid;padding-bottom: 20px;">
        <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>控制台</el-breadcrumb-item>
        </el-breadcrumb>
    </div> 
    <div class="">
        <div class="welcome">
            <div class="title">欢迎登录AI智能曲谱管理后台！</div>
            <div class="lastlogintime">上次登录IP：{{userinfo.u_lastlogintime}}</div>
            <div class="loginip">上次登录地址：{{userinfo.u_loginip}}</div>
        </div>
        <el-row>
            <el-col :span="14"><div class="grid-content ep-bg-purple" />
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <span style="font-size:14px;">求谱列表</span>
                            <el-button class="button" text>更多</el-button>
                        </div>
                    </template>
                    <el-table ref="multipleTableRef" :data="findmusix" style="width: 100%" @selection-change="handleSelectionChange" align="center" border empty-text="暂无数据">
                        <el-table-column type="index" label="序号" width="80" align="center" show-overflow-tooltip="true"/>
                        <el-table-column property="fm_title" label="曲谱名" min-width="200" align="left" header-align="center" show-overflow-tooltip="true"/>
                        <el-table-column property="u_nickname" label="最早求谱人" width="180" align="center" header-align="center" show-overflow-tooltip="true" />
                        <el-table-column property="fm_addtime" sortable label="求谱时间" width="180" align="center" header-align="center" show-overflow-tooltip="true" />
                        <el-table-column property="fm_count" sortable label="求谱人数" width="120" align="center" header-align="center" show-overflow-tooltip="true" />
                    </el-table>
                </el-card>
            </el-col>
            <!-- 
            <el-col :span="8"><div class="grid-content ep-bg-purple-light" />
                <el-card class="box-card">
                    <template #header>
                    <div class="card-header">
                        <span>Card name</span>
                        <el-button class="button" text>Operation button</el-button>
                    </div>
                    </template>
                    <div v-for="o in 4" :key="o" class="text item">{{ 'List item ' + o }}</div>
                </el-card>
            </el-col>
            <el-col :span="8"><div class="grid-content ep-bg-purple" />
                <div class="block text-center" m="t-4">
                    <el-carousel trigger="click" height="150px">
                    <el-carousel-item v-for="item in 4" :key="item">
                        <h3 class="small justify-center" text="2xl">{{ item }}</h3>
                    </el-carousel-item>
                    </el-carousel>
                </div>
            </el-col> -->
        </el-row>
    </div>
</template>
<script>
    export default {
        name: 'Console',
        data () {
            return {
                userinfo:'',
                findmusix:[],
            }
        },
        mounted() {
            let token = sessionStorage.getItem('token')
            this.userinfo = JSON.parse(token)
            //获取求谱列表
            this.$request({
                url: this.$baseUrl + '/musicxml/findmusicxml/getListApi.html',
                method: "POST",
                data:{list:10}
            }).then(res=>{
                if (res.code == 1000) {
                    this.findmusix = res.data
                }
            })
        },
        methods:{
            menu(path){
                this.mainArea = 'MusicXmlCate'
                this.$router.push(path)
            },
            exit(){
                //清理token
                localStorage.removeItem('token')
                this.$router.push('/login')
            }
        }
    }

</script>
<style>
    .welcome{
        width:100%;
        height:auto;
        margin-bottom: 20px;
    }
    .title{
        font-size:16px;
        font-weight: bold;
        line-height: 30px;
        color:#333;
    }
    .lastlogintime{
        font-size:14px;
        line-height: 30px;
        color:#666;
    }
    .loginip{
        font-size:14px;
        line-height: 30px;
        color:#666;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .text {
        font-size: 14px;
    }
    .item {
        margin-bottom: 18px;
    }
    .box-card {
        width: 98%;
    }




    .demonstration {
        color: var(--el-text-color-secondary);
    }
    .el-carousel__item h3 {
        color: #475669;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
        text-align: center;
    }
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
</style>