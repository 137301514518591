<template>
    <div class="common-layout" style="height:100%;">
        <el-container style="height:100%;">
            <el-aside width="220px">
                <div class="logo" style="background:#0085E8 !important;color:#fff !important;"><router-link to="/index/console">AI曲谱管理系统</router-link></div>
                <el-menu default-active="" class="el-menu-vertical-demo" style="width:220px;height:calc(100% - 61px);">
                    <el-menu-item index="1">
                        <template #title>
                            <el-icon><HomeFilled /></el-icon>
                            <span><router-link to="/index/console">控制台</router-link></span>
                        </template>
                    </el-menu-item>
                    <el-sub-menu index="2">
                        <template #title>
                            <el-icon><DocumentCopy /></el-icon>
                            <span>我的曲谱</span>
                        </template>
                        <el-menu-item index="2-1"><router-link to="/index/mymusicxmlcate">分类管理</router-link></el-menu-item>
                        <el-menu-item index="2-2"><router-link to="/index/mymusicxml">曲谱列表</router-link></el-menu-item>
                        <!-- <el-sub-menu index="2-3">
                            <template #title><span>其它管理</span></template>
                            <el-menu-item index="2-3-1">三级菜单</el-menu-item>
                        </el-sub-menu> -->
                    </el-sub-menu>
                    <el-sub-menu index="3">
                        <template #title>
                            <el-icon><Star /></el-icon>
                            <span>我的收藏</span>
                        </template>
                        <el-menu-item index="3-1"><router-link to="/index/collectcate">分类管理</router-link></el-menu-item>
                        <el-menu-item index="4-2"><router-link to="/index/collect">曲谱列表</router-link></el-menu-item>
                        <!-- <el-sub-menu index="2-3">
                            <template #title><span>其它管理</span></template>
                            <el-menu-item index="2-3-1">三级菜单</el-menu-item>
                        </el-sub-menu> -->
                    </el-sub-menu>
                    <el-menu-item index="4">
                        <el-icon><setting /></el-icon>
                        <template #title><router-link to="/index/setting">个人设置</router-link></template>
                    </el-menu-item>
                    <el-sub-menu index="5" v-if="userinfo.u_manager == 1">
                        <template #title>
                            <el-icon><Operation /></el-icon>
                            <span>曲谱管理</span>
                        </template>
                        <el-menu-item index="5-1"><router-link to="/index/musicxml">曲谱列表</router-link></el-menu-item>
                        <el-sub-menu index="5-2">
                            <template #title><span>私密教材</span></template>
                            <el-menu-item index="5-2-1"><router-link to="/index/privatecate">教材分类</router-link></el-menu-item>
                            <el-menu-item index="5-2-2"><router-link to="/index/private">教材列表</router-link></el-menu-item>
                        </el-sub-menu>
                        <el-menu-item index="5-3"><router-link to="/index/findmusicxml">求谱列表</router-link></el-menu-item>
                        <el-menu-item index="5-4"><router-link to="/index/search">搜索曲谱</router-link></el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="6" v-if="userinfo.u_manager == 1">
                        <template #title>
                            <el-icon><Avatar /></el-icon>
                            <span>用户管理</span>
                        </template>
                        <el-menu-item index="6-1"><router-link to="/index/user">用户列表</router-link></el-menu-item>
                        <el-menu-item index="6-2"><router-link to="/index/teacher">教师认证</router-link></el-menu-item>
                        <!-- <el-menu-item index="6-3"><router-link to="/index/group">角色管理</router-link></el-menu-item> -->
                        <!-- <el-menu-item index="6-4"><router-link to="/index/rules">权限列表</router-link></el-menu-item> -->
                    </el-sub-menu>
                </el-menu>
            </el-aside>
            <el-container>
                <el-header style="padding:0;background-color: #409eff;">
                    <el-dropdown style="line-height:59px;float:right;padding-right:40px;color:#fff;">
                        <span class="el-dropdown-link">欢迎您，{{userinfo.u_nickname}}
                            <!-- <el-icon class="el-icon--right">
                                <arrow-down />
                            </el-icon> -->
                        </span>
                        <el-button type="danger" style="margin-left:20px;" @click="exit()"><el-icon><SwitchButton /></el-icon> 退出</el-button>
                        <!-- <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item><router-link to="/index/setting">修改资料</router-link></el-dropdown-item>
                                <el-dropdown-item @click="exit();">退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </template> -->
                    </el-dropdown>
                </el-header>
                <el-main>
                    <router-view></router-view>
                    <!-- <component :is="mainArea"></component> -->
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
    import Console from './Console.vue'
    export default {
        name: 'Index',
        data () {
            return {
                mainArea:'Console',
                userinfo:'',
            }
        },
        components:{
            Console
		},
        mounted() {
            let token = sessionStorage.getItem('token')
            this.userinfo = JSON.parse(token)
            //获取菜单
            this.getMenu()
        },
        methods:{
            getMenu(){
                this.$request({
                    url: this.$baseUrl + '/musicxml/common/getMenu.html',
                    method:'POST',
                    success:function(res){
                        if (res.code == 1000) {
                            
                        }
                    }
                })
            },
            exit(){
                //清理token
                sessionStorage.removeItem('token')
                this.$router.push('/login')
            }
        }
    }
</script>

<style>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }
    .el-menu-item a{
        color:var(--el-menu-text-color);display: block;width:100%;
    }
    .logo{
        background:#0085E8 !important;
        height:60px !important;
    }
    .logo a{
        color:#fff !important;
    }
    .is-active{
        background-color: #ecf5ff !important;
        color: black !important;
    }
</style>