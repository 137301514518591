//1.按需导入对应的函数
import { createRouter,createWebHashHistory } from 'vue-router'

//导入组件
import Login from './components/Login.vue'
import Index from './components/Index.vue'
import Console from './components/Console.vue'
import MyMusicXmlCate from './components/mymusicxmlcate/MyMusicXmlCate.vue'
import MyMusicXml from './components/mymusicxml/MyMusicXml.vue'
import CollectCate from './components/collectcate/CollectCate.vue'
import Collect from './components/collect/Collect.vue'
import Setting from './components/setting/Setting.vue'
import MusicXml from './components/musicxml/MusicXml.vue'
import FindMusicXml from './components/findmusicxml/FindMusicXml.vue'
import Teacher from './components/teacher/Teacher.vue'
import User from './components/user/User.vue'
import Private from './components/private/Private.vue'
import PrivateCate from './components/private/PrivateCate.vue'
import Test from './components/Test.vue'
import mymusicxmlout from './components/mymusicxmlout.vue'
import search from './components/search/search.vue'

//2.创建路由对象
const router = createRouter({
    history:createWebHashHistory(),
    //修改路由高亮类名
    linkActiveClass: 'current',
    routes:[
        { path: '/', redirect:'/login'},
        { path: '/login', component:Login, name:'login',meta: { title: "AI智能曲谱内容管理中心 - 逐梦音乐" }},
        { path: '/mymusicxmlout', component:mymusicxmlout, name:'mymusicxmlout',meta: { title: "音乐" }},
        { path: '/index', redirect:'/index/console'},
        { path: '/index', component:Index, name:'index',
            children: [
                { path: 'console', component:Console, name:'console', meta: { title: "AI智能曲谱内容管理中心 - 逐梦音乐" }},
                { path: 'mymusicxmlcate', component:MyMusicXmlCate, name:'mymusicxmlcate', meta: { title: "我的曲谱分类列表 - 逐梦音乐" }},
                { path: 'mymusicxml', component:MyMusicXml, name:'mymusicxml', meta: { title: "我的曲谱列表 - 逐梦音乐" }},
                { path: 'collectcate', component:CollectCate, name:'collectcate', meta: { title: "收藏分类列表 - 逐梦音乐" }},
                { path: 'collect', component:Collect, name:'collect', meta: { title: "收藏列表 - 逐梦音乐" }},
                { path: 'setting', component:Setting, name:'setting', meta: { title: "个人设置 - 逐梦音乐" }},
                { path: 'musicxml', component:MusicXml, name:'musicxml', meta: { title: "曲谱列表 - 逐梦音乐" }},
                { path: 'findmusicxml', component:FindMusicXml, name:'findmusicxml', meta: { title: "求谱列表 - 逐梦音乐" }},
                { path: 'user', component:User, name:'user', meta: { title: "用户列表 - 逐梦音乐" }},
                { path: 'teacher', component:Teacher, name:'teacher', meta: { title: "教师认证 - 逐梦音乐" }},
                { path: 'private', component:Private, name:'private', meta: { title: "教材列表 - 逐梦音乐" }},
                { path: 'privatecate', component:PrivateCate, name:'privatecate', meta: { title: "教材分类 - 逐梦音乐" }},
                { path: 'search', component:search, name:'search', meta: { title: "搜索曲谱 - 逐梦音乐" }},

                { path: 'test', component:Test, name:'test', meta: { title: "测试 - 逐梦音乐" }},
            ]
        },
    ],
})
//全局导航守卫
router.beforeEach((to, from, next) => {
    //to 目标路由对象
    //from 当前导航正要离开的路由对象
    //next 是一个函数，表示放行
    if (to.path == '/login' || to.path == '/') {
        next();
    } else {
        const token = sessionStorage.getItem('token')
        // token = JSON.parse(token)
        // const token = localStorage.getItem('token');
        if (token === 'undefined' || token == null) {
            next('/login');
        } else {
            next();
        }
    }
})

//3.向外共享路由实例对象
export default router