<template>
    <div style="width: 100%;height: auto;overflow: hidden;margin-bottom: 20px;border-bottom: 1px #eee solid;padding-bottom: 20px;">
        <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><router-link to="/index/setting">个人设置</router-link></el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-form :model="form" label-width="120px" :rules="baseRules" ref="baseForm" style="width:500px;">
        <el-form-item label="头像" prop="headimg">
            <el-tooltip
                class="box-item"
                effect="dark"
                content="请上传jpg|jpeg|png格式文件，大小不超过500kb"
                placement="right-start"
            >
                <el-upload
                    class="avatar-uploader"
                    action="#" 
                    :http-request="uploadImgAction"
                    :show-file-list="false"
                    :before-upload="beforeImageUpload"
                >
                    <img v-if="form.headimg" :src="form.headimg" :onerror="defaultimg" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
            </el-tooltip>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
            <el-input v-model="form.nickname" placeholder="请输入昵称"/>
        </el-form-item>
        <el-form-item label="账号">
            <el-input v-model="form.account" v-show="account" autocomplete="off" placeholder="请设置账号"/>
            <el-input v-model="form.account" v-show="!account" autocomplete="off" disabled placeholder="请设置账号"/>
        </el-form-item>
        <el-form-item label="原密码" v-show="!account">
            <el-input v-model="form.oldpassword" type="password" autocomplete="off" placeholder="不修改密码请留空"/>
        </el-form-item>
        <el-form-item label="新密码">
            <el-input v-model="form.newpassword" type="password" autocomplete="new-password" placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item label="确认密码">
            <el-input v-model="form.confirm" type="password" autocomplete="off" placeholder="请再次输入密码"/>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitBtn">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
    import { ElMessage } from 'element-plus'
    export default {
        name: 'Setting',
        components:{ },
        data(){
            return {
                account:true,
                //默认图片
                defaultimg: 'this.src="'+require('../../assets/thumb.png')+'"',
                form:{
                    headimg:'',
                    nickname:'',
                    account:'',
                    oldpassword:'',
                    newpassword:'',
                    confirm:'',
                },
                //表单验证
                baseRules: {
                    headimg:[{ required: true, message: '请上传头像', trigger: 'blur' }],
                    nickname:[{ required: true, message: '请输入昵称', trigger: 'blur' }],
                },
            }
        },
        mounted() {
            this.getData()
        },
        methods:{
            uploadImgAction(option){
                let param = new FormData();
                param.append('file', option.file);
                this.$request({
                    url: this.$baseUrl + '/musicxml/upload/image.html',
                    method: "POST",
                    data:param,
                }).then(res=>{
                    if (res.code == 1000) {
                        this.form.headimg = res.data
                        ElMessage.success('图片上传成功！')
                    } else {
                        ElMessage.error('图片上传失败！')
                    }
                })
            },
            beforeImageUpload(rawFile){
                console.log(rawFile)
                if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'image/jpg') {
                    ElMessage.error('文件格式错误')
                    return false
                } else if (rawFile.size / 1024 > 50) {
                    ElMessage.error('文件大小不能超过500kb')
                    return false
                }
                return true
            },
            //个人数据
            getData(){
                //获取列表数据
                this.$request({
                    url: this.$baseUrl + '/musicxml/setting/index.html',
                    method: "POST",
                    data:{user:'setting'},
                }).then(res=>{
                    if (res.code == 1000) {
                        this.form.headimg = res.data.u_headimg
                        this.form.nickname = res.data.u_nickname
                        this.form.account = res.data.u_username
                        if (res.data.u_username) {
                            this.account = false
                        }
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
                
            },
            //提交
            submitBtn(){
                this.$refs.baseForm.validate((valid) => {
                    if(!valid) {
                        return false
                    }
                    this.$request({
                        url: this.$baseUrl + '/musicxml/setting/index.html',
                        method: "put",
                        data:this.form
                    }).then(res=>{
                        if (res.code == 1000) {
                            ElMessage.success(res.msg);
                        } else {
                            ElMessage.error(res.msg);
                        }
                    })
                })
            }
        }
    }
    
</script>