<template>
    <div
    style="
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: 20px;
      border-bottom: 1px #eee solid;
      padding-bottom: 20px;
    "
  >
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>曲谱管理</el-breadcrumb-item>
      <el-breadcrumb-item
        ><router-link to="/index/search"
          >搜索用户</router-link
        ></el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
  <el-row class="pb20">
        <el-col :span="16"></el-col>
        <el-col :span="8" class="tr">
            <el-input v-model="tableData.condition.keyword" placeholder="请输入关键词" class="width200"/>
            <el-button type="primary" style="position:relative;top:-2px;margin-left:2px;" @click="searchi(tableData.condition.keyword)"><el-icon class="pr5"><Search /></el-icon> 搜索</el-button>
        </el-col>
    </el-row>
    <el-table ref="multipleTableRef" :data="tableData.list" style="width: 100%" @selection-change="handleSelectionChange" align="center" border empty-text="暂无数据">
        <el-table-column type="index" label="序号" width="160" align="center" :index="hIndex" show-overflow-tooltip="true"/>
        <el-table-column property="s_keyword" sortable label="搜索词" min-width="200" align="center" header-align="center" show-overflow-tooltip="true" formatter/>
        <el-table-column property="u_nickname" label="搜索用户" width="300" align="left" header-align="center" show-overflow-tooltip="true"/>
        <el-table-column property="u_phone" sortable label="手机号" width="230" align="center" header-align="center" show-overflow-tooltip="true" />
        <el-table-column property="s_updatetime" sortable label="搜索时间" width="300" align="center" header-align="center" show-overflow-tooltip="true" />
        <el-table-column label="操作" width="160" align="center" show-overflow-tooltip="true">
            <template #default="scope">
                <el-popconfirm title="确认要删除该搜索记录吗？" @confirm="del(scope.$index, scope.row)">
                    <template #reference>
                         <el-button size="small" type="danger"><el-icon class="pr2"><Delete /></el-icon> 删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>
    <div class="mt20">
        <el-pagination
            v-model:currentPage="tableData.condition.page"
            v-model:page-size="tableData.condition.limit"
            :page-sizes="[20, 30, 40, 50, 100]"
            :small="small"
            :disabled="disabled"
            :background=true
            layout="prev, pager, next, total"
            :total="tableData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>

	export default {
		data(){
            return{
                tableData : {
                    list:[],
                    total:0,
                    condition:{
                        keyword:'',
                        page:1,
                        limit:10,
                    },
                },
            }
        },
        mounted(){
            this.search()
        },
        methods:{
            //搜索用户列表
            search(){
                this.$request({
                    url:this.$baseUrl +'/musicxml/search/list.html',
                    method:'POST',
                    data:this.tableData.condition,
                }).then(res=>{
                    console.log(res)
                    if(res.code==1000){
                        console.log(res.data.data);
                        this.tableData.list=res.data.data
                        this.tableData.total=res.data.total
                    }else{
                        ElMessage.error(res.msg);
                    }
                })
            },
            handleCurrentChange(e){
                this.tableData.condition.page = e
                this.search()
            },
            del(index,row){
                console.log(index);
                console.log(row);
                this.$request({
                    url: this.$baseUrl + '/musicxml/search/edit.html',
                    method: "delete",
                    data:{id:row.s_id}
                }).then(res=>{
                    if (res.code == 1000) {
                        this.search()
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
            },
            searchi(e){
                // this.tableData.condition.keyword=e
                this.search()
            }
        }
	}
</script>

<style lang="less">

</style>