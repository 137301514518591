<template>
	<router-view v-wechat-title="$route.meta.title"/>
</template>

<script>
	export default {
		name: 'App',
	}
</script>

<style lang="less">

</style>