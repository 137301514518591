<template>

    <div class="login">
        <div class="content clearfix">
            <div class="left fl">
                <img src="../assets/login-left-img.jpg" alt="">
            </div>
            <div class="right fr">
                <div class="title">
                    <span :class="cur == 1 ? 'cur' : ''" @click="loginMethod" data-type="1">微信扫码登录</span>
                    <span :class="cur == 2 ? 'cur' : ''" @click="loginMethod" data-type="2">账号密码登录</span>
                </div>
                <div :class="cur == 1 ? 'wechat' : 'wechat hide'" v-show="qrcode">
                    <div class="qrcode">
                        <img :src="qrcode" alt="扫码登录">
                        <div class="shixiao" @click="getQrcode()" v-show="expire">
                            <el-icon><RefreshRight /></el-icon>
                            <p>已失效</p>
                        </div>
                    </div>
                    <p>请使用微信扫码登录</p>
                </div>
                <div :class="cur == 2 ? 'account' : 'account hide'" style="font-size:20px;">
                    <input v-model.trim="username" autocomplete="off" placeholder="请输入手机号/用户名" class="yhm">
                    <input v-model.trim="password" type="password" autocomplete="new-password" placeholder="请输入密码" class="mm">
                    <div class="yzmcot">
                        <input v-model.trim="captcha" autocomplete="off" placeholder="请输入验证码" class="mm2">
                        <a href="javascript:;" @click="refreshCode">
                            <Captcha :identifyCode="identifyCode" :contentWidth="120" :contentHeight="41"></Captcha>
                        </a>
                    </div>
                    <div class="rememberbox">
                        <div class="rememberboxleft">
                            <el-checkbox label="记住我" v-model="remember"/>
                        </div>
                        <div class="rememberboxright">忘记密码？</div>
                    </div>
                    <div class="loginboxbtn" @click="onLogin();">登 录</div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import Captcha from './Captcha'
import { ElMessage } from 'element-plus'
export default {
    name: 'Login',
    data() {
        return {
            cur:1,
            username: '',
            password: '',
            captcha: '',
            identifyCode: '',
            remember:'',
            customer:'',
            qrcode:'',
            expire:false,
            timer:null,
        }
    },
    components: { Captcha },
    created() {
        document.onkeydown = (e) => {
            e = window.event || e
            if (e.keyCode == 13) {
                this.onLogin();
            }
        }
    },
    mounted() {
        //获取登录二维码
        this.getQrcode()
        this.refreshCode()
    },
    methods: {
        getQrcode(){
            this.expire = false
            this.qrcode = ''
            // let timer //定时器
            var x = "123456789poiuytrewqasdfghjklmnbvcxzQWERTYUIPLKJHGFDSAZXCVBNM";
            var tmp = "";
            for (var i=0;i<16;i++) {
                tmp += x.charAt(Math.ceil(Math.random()*10000000000)%x.length);
            }
            this.customer = tmp
            this.$request({
                url: this.$baseUrl + '/musicxml/login/getQrcode.html',
                method: "post",
                data:{customer:this.customer}
            }).then(res=>{
                clearInterval(this.timer)
                if (res.code == 1000) {
                    this.qrcode = res.data
                    let time = 30
                    //拿到二维以后，30秒有效期，期间，每隔1秒请求一次服务器
                    this.timer = setInterval(() => {
                        time--
                        if (time <= 0) {
                            clearInterval(this.timer)
                            //显示失效及刷新图标
                            this.expire = true
                        }
                        this.$request({
                            url: this.$baseUrl + '/musicxml/login/scanLogin.html',
                            method: "post",
                            data:{customer:this.customer}
                        }).then(res=>{
                            if (res.code == 1000) {
                                clearInterval(this.timer)
                                //设置token，并跳转到后台
                                sessionStorage.setItem('token',JSON.stringify(res.data))
                                localStorage.setItem('clientTime',Math.round(new Date().getTime()/1000).toString()) //客户端时间
                                localStorage.setItem('tokenTime',res.time) //Token时间
                                localStorage.setItem('serverTime',res.time) //服务端时间
                                this.$router.push(res.other);
                            }
                        })
                    }, 2000);
                }
            })
        },
        async onLogin() {
            if (!this.username) {
                ElMessage.error('请输入账号');
                this.refreshCode()
                return
            }
            if (!this.password) {
                ElMessage.error('请输入密码');
                this.refreshCode()
                return
            }
            if (!this.captcha) {
                ElMessage.error('请输入验证码');
                this.refreshCode()
                return
            } else {
                //验证验证码
                if (this.captcha.toLowerCase() !== this.identifyCode.toLowerCase()) {
                    ElMessage.error('验证码不正确');
                    this.refreshCode()
                    return
                }
            }
            if (this.remember) {
                this.remember = 1;
            } else {
                this.remember = 0;
            }
            const res = await this.$request({ 
                url: this.$baseUrl + '/musicxml/login/index.html', 
                method: "post",
                data:{username:this.username,password:this.password,captcha:this.captcha,remember:this.remember}})
            if (res.code == 1000) {
                //设置token，并跳转到后台
                sessionStorage.setItem('token',JSON.stringify(res.data))
                localStorage.setItem('clientTime',Math.round(new Date().getTime()/1000).toString()) //客户端时间
                localStorage.setItem('tokenTime',res.time) //Token时间
                localStorage.setItem('serverTime',res.time) //服务端时间
                this.$router.push(res.other);
                // console.log(res.other)
                clearInterval(this.timer)
                this.timer=null
            } else {
                ElMessage.error(res.msg);
                this.refreshCode()
            }
        },
        // 登录切换
        loginMethod(e) {
            this.cur = e.target.dataset.type
        },
        // 重置验证码
        refreshCode() {
            const str = '1234567890abcdefjhijklinopqrsduvwxyz'
            this.identifyCode = ''
            for (let i = 0; i < 4; i++) {
                this.identifyCode += str[Math.floor(Math.random() * (str.length - 0) + 0)]
            }
        },
    },
}
</script>
<style lang="less" scoped>
    .qrcode{
        width:207px;
        height:207px;
        position: relative;
        margin:0 auto;
    }
    .qrcode img{
        width:207px;
    }
    .shixiao{
        width:100%;
        height:calc(100% - 80px);
        background:rgba(0,0,0,0.6);
        position: absolute;
        left:0;
        top:0;
        color: #fff;
        font-size: 14px;
        padding-top:80px;
        cursor: pointer;
    }
    .shixiao p{
        padding-top:0 !important;
        color:#fff !important
    }
    .shixiao .el-icon{
        font-size:24px;
    }
    .wechat{
        text-align: center;
        padding:20px 0;
    }
    // .wechat img{
    //     padding:10px;
    //     border:1px #eee solid;
    // }
    .wechat p{
        width:100%;
        height:auto;
        font-size:14px;
        line-height:24px;
        color:#333;
        padding-top:20px;
    }
    .yzmcot {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin: 25px 0 15px;
    }
    .mm2 {
        width: 188px;
        height: auto;
        overflow: hidden;
        background: #fff;
        border: 1px solid #efefef;
        display: block;
        outline: none;
        font-size: 14px;
        color: #333;
        padding: 10px;
        float: left;
        border-radius: 5px;
    }
    .yzmcot a {
        width: 126px;
        float: right;
        text-align: center;
        display: block;
    }
    .login {
        width: 100%;
        height: 100%;
        background: url(../assets/login-bg.jpg) no-repeat;
        background-size: 100%;
        overflow: hidden;
    }

    .content {
        width: 1200px;
        height: 500px;
        background: #fff;
        margin: 200px auto 0;
        overflow: hidden;
        box-shadow: 0px 0px 20px 0px #00000059;
    }

    .left {
        width: 50%;
        height: auto;
    }

    .left img {
        max-width: 100%;
    }

    .right {
        width: calc(50% - 40px);
        padding: 20px
    }

    .title {
        width: 60%;
        height: auto;
        padding: 20px 20% 10px;
    }

    .title span {
        width: calc(50% - 1px);
        height: auto;
        font-size: 20px;
        font-weight: bold;
        color: #666;
        margin: 30px 0 20px 0;
        text-align: center;
        display: inline-block;
        cursor: pointer;
    }

    .title span:first-child {
        border-right: 2px #ddd solid;
    }

    .cur {
        color: #38b1c2 !important;
    }

    .account {
        width: 60%;
        height: auto;
        padding: 0 20%;
        padding-top: 20px;
    }

    .yhm {
        width: calc(100% - 50px);
        height: auto;
        overflow: hidden;
        background: #fff;
        border: 1px solid #efefef;
        display: block;
        outline: none;
        font-size: 14px;
        color: #333;
        background: url(../assets/11.jpg) no-repeat left center;
        padding: 10px 0px 10px 46px;
        border-radius: 5px;
    }

    .mm {
        width: calc(100% - 50px);
        height: auto;
        overflow: hidden;
        background: #fff;
        border: 1px solid #efefef;
        display: block;
        outline: none;
        font-size: 14px;
        color: #333;
        background: url(../assets/12.jpg) no-repeat left center;
        padding: 10px 0px 10px 46px;
        border-radius: 5px;
        margin: 25px 0;
    }

    .loginboxbtn {
        width: 100%;
        height: auto;
        overflow: hidden;
        background: #e50012;
        text-align: center;
        font-size: 17px;
        color: #fff;
        text-align: center;
        font-weight: bold;
        padding: 8px 0;
        margin-top: 10px;
        border-radius: 5px;
        line-height: 30px;
        cursor: pointer;
    }

    .rememberbox {
        width: 100%;
        height: auto;
        overflow: hidden;
        line-height: 30px;
    }

    .rememberboxleft {
        font-size: 12px;
        color: #666;
        width: auto;
        overflow: hidden;
        float: left;
    }

    .rememberboxright {
        font-size: 12px;
        color: #666;
        width: auto;
        overflow: hidden;
        float: right;
    }

    .rememberboxright a {
        color: #666;
    }
</style>