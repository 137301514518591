<template>
    <div style="width: 100%;height: auto;overflow: hidden;margin-bottom: 20px;border-bottom: 1px #eee solid;padding-bottom: 20px;">
        <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: '/index/console' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>我的曲谱</el-breadcrumb-item>
            <el-breadcrumb-item><router-link to="/index/mymusicxmlcate">分类管理</router-link></el-breadcrumb-item>
        </el-breadcrumb>
    </div>

    <el-row class="pb20">
        <el-col :span="16">
            <el-button type="primary" @click="add();"><el-icon class="pr5"><Plus /></el-icon> 添加分类</el-button>
        </el-col>
        <el-col :span="8" class="tr">
            <el-input v-model="tableData.condition.keyword" placeholder="请输入名称" class="width200"/>
            <el-button type="primary" style="position:relative;top:-2px;margin-left:2px;" @click="search"><el-icon class="pr5"><Search /></el-icon> 搜索</el-button>
        </el-col>
    </el-row>

    <el-table ref="multipleTableRef" :data="tableData.list" style="width: 100%" @selection-change="handleSelectionChange" align="center" border empty-text="暂无数据" @row-click="movetu">
        <el-table-column type="index" label="序号" width="100" align="center" show-overflow-tooltip="true"/>
        <el-table-column property="mc_name" label="名称" width="200" align="center" show-overflow-tooltip="true"/>
        <el-table-column property="mc_mark" label="备注" align="left" header-align="center" show-overflow-tooltip="true" />
        <el-table-column property="mc_addtime" sortable label="添加时间" width="200" align="center" header-align="center" show-overflow-tooltip="true" />
        <el-table-column label="操作" width="170" align="center" show-overflow-tooltip="true">
            <template #default="scope">
                <el-button size="small" @click="modify(scope.$index, scope.row)"><el-icon class="pr2"><Edit /></el-icon> 编辑</el-button>
                <el-popconfirm title="确认要删除该分类吗？" @confirm="del(scope.$index, scope.row)">
                    <template #reference>
                         <el-button size="small" type="danger"><el-icon class="pr2"><Delete /></el-icon> 删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>
    <div class="mt20">
        <el-pagination
            v-model:currentPage="tableData.condition.page"
            v-model:page-size="tableData.condition.limit"
            :page-sizes="[20, 30, 40, 50, 100]"
            :small="small"
            :disabled="disabled"
            :background=true
            layout="prev, pager, next, total"
            :total="tableData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>

    <!-- 添加编辑窗口 -->
    <el-dialog v-model="editWindow" :title="title" :close-on-click-modal="false" width="500px" draggable="true">

        <el-form :model="form" label-width="120px" :rules="baseRules" ref="baseForm">
            <el-form-item label="名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入名称"/>
            </el-form-item>
            <el-form-item label="添加时间" class="time">
                <el-col>
                    <el-date-picker v-model="form.addtime" type="datetime" placeholder="请选择日期"/>
                </el-col>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.mark" type="textarea" placeholder="请输入备注"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitBtn">保存</el-button>
                <el-button @click="cancleBtn">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>


<script>
    import { ElMessage } from 'element-plus'
    export default {
        name: 'MyMusicXmlCate',
        data(){
            return {
                editWindow:false,
                title:'添加分类',
                tableData : {
                    list:[],
                    total:0,
                    condition:{
                        status:'',
                        keyword:'',
                        page:1,
                        limit:10,
                    },
                },
                form:{
                    id:'',
                    name:'',
                    addtime:'',
                    mark:'',
                },
                total:0,
                baseRules: {
                    name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
                },
            }
        },
        mounted() {
            this.getListData()
        },
        created() {
            //回车键绑定搜索
            document.onkeydown = (e) => {
                e = window.event || e
                if (e.keyCode == 13 || e.keyCode == 100) {
                    this.search();
                }
            }
        },
        methods:{
            handleSizeChange(e){
                console.log(e)
            },
            handleCurrentChange(e){
                this.tableData.condition.page = e
                this.getListData()
            },
            getListData(){
                //获取列表数据
                this.$request({
                    url: this.$baseUrl + '/musicxml/mymusicxmlcate/list.html',
                    method: "POST",
                    data:this.tableData.condition
                }).then(res=>{
                    if (res.code == 1000) {
                        this.tableData.list = res.data.data
                        this.tableData.total = res.data.total
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
            },
            add(){
                this.title = '添加分类'
                this.form.name = ''
                this.form.addtime = ''
                this.form.mark = ''
                this.form.id = ''
                this.editWindow = true
            },
            modify(index,data){
                this.form.id = data.mc_id
                this.form.name = data.mc_name
                this.form.addtime = data.mc_addtime
                this.form.mark = data.mc_mark
                this.title = '编辑分类'
                this.editWindow = true
            },
            del(index,data){
                this.form.id = data.mc_id
                this.form.name = data.mc_name
                this.form.addtime = data.mc_addtime
                this.form.mark = data.mc_mark
                this.$request({
                    url: this.$baseUrl + '/musicxml/mymusicxmlcate/edit.html',
                    method: "delete",
                    data:this.form
                }).then(res=>{
                    if (res.code == 1000) {
                        this.getListData();
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
            },
            cancleBtn(){
                this.editWindow = false
            },
            search(){
                this.getListData()
            },
            submitBtn(){
                this.$refs.baseForm.validate((valid) => {
                    if(!valid) {
                        return false
                    }
                    this.$request({
                        url: this.$baseUrl + '/musicxml/mymusicxmlcate/edit.html',
                        method: "post",
                        data:this.form
                    }).then(res=>{
                        if (res.code == 1000) {
                            this.editWindow = false
                            this.getListData();
                        } else {
                            ElMessage.error(res.msg);
                        }
                    })
                })
            },
            movetu(row){
                console.log(row.mc_id);
                this.$router.push({name:'mymusicxml',params:{pid1:row.mc_id,pname1:row.mc_name}})
            }
        }
    }
    
</script>
<style lang="less">
    .time .el-input{
        width:100% !important;
    }
</style>